/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import dayjs from "dayjs";
import "yet-another-react-lightbox/styles.css";
import {
  SiteService,
  Tour,
  AdminService,
  Package
} from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./../Home/calendar.css";
import { ContactForm } from "../../components/ContactForm";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "../../components/Breadcrumbs";

const rentalslideroption = {
  dots: false,
  nav: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  centerMode: false,

  // allow exactly 3 slides to show on screen
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 4000
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 4000
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 4000
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000
      }
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000
      }
    }
  ]
};

const TourPage = () => {
  // get the tourId and date
  const params = useParams<{ tourId: string; date: string }>();

  const tourId = params.tourId?.split("-").pop();
  const date = params.date;
  const { t, i18n } = useTranslation();

  const bigImgSliderRef = useRef<any>(null);
  const thumbnailSliderRef = useRef<any>(null);

  const [tour, setTour] = useState<Tour | undefined>(undefined);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [seatsAvailable, setSeatsAvailable] = useState<number[]>([]);
  const [whatsIncluded, setWhatsIncluded] = useState<string[][]>([]);

  const [tours, setTours] = useState<Tour[]>([]);

  const [soldOutDates, setSoldOutDates] = useState<Date[]>([]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    date ? new Date(parseInt(date)) : null
  );
  const [selectedPackage, setSelectedPackage] = useState<string>("");

  const [packages, setPackages] = useState<Package[]>([]);

  // const [itineraries, setItineraries] = useState<string[][]>([]);

  useEffect(() => {
    // set title of the page
    document.title = "First Gear Club | Tour Details";
  }, []);

  useEffect(() => {
    async function getTours() {
      const tours = await AdminService.getTours().catch((err) => {
        console.error(err);
        return [];
      });
      setTours(tours.filter((t) => t.id !== tourId));
    }

    getTours();
  }, []);

  useEffect(() => {
    if (!tour || !tours || tours.length === 0) {
      return;
    }

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({ items: null });
    // @ts-ignore
    window.dataLayer.push({
      event: "view_item_list",
      currency: "EUR",
      item_list_name: tour.name,
      items: tours.map((tour, index) => ({
        item_id: tour.id,
        item_name: tour.name,
        item_category: tour.startLocation,
        item_variant: "",
        price: tour.packages[0].price,
        quantity: 1,
        index: index + 1
      }))
    });
  }, [tours, tour]);

  useEffect(() => {
    async function asyncCall() {
      const res = await Promise.all([
        AdminService.getTourById(tourId || ""),
        SiteService.getTourAvailableDates(tourId || "")
      ]);

      const localTour = res[0];
      localTour.photos = localTour.photos.reverse();
      setTour(localTour);
      if (localTour.startLocation === "Training") {
        setSelectedPackage(localTour.packages[0].name);
      }
      setAvailableDates(res[1].availableDates.map((date) => new Date(date)));
      setSeatsAvailable(res[1].seatsAvailable);
      setSoldOutDates(res[1].soldOutDates.map((date) => new Date(date)));

      // set data layer
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ items: null });
      // @ts-ignore
      window.dataLayer.push({
        event: "view_item",
        currency: "EUR",
        value: 300,
        items: [
          {
            item_id: tourId,
            item_name: localTour.name,
            item_category: localTour.startLocation,
            item_variant: "",
            price: localTour.packages[0].price,
            quantity: 1,
            index: 0
          }
        ]
      });

      // set itineraries

      // const itinerariyDay1 = res[0].itinerary
      //   .filter((itinerary) => itinerary.name === "Day 1")
      //   .map((itinerary) => itinerary.photo);
      // const itinerariyDay2 = res[0].itinerary
      //   .filter((itinerary) => itinerary.name === "Day 2")
      //   .map((itinerary) => itinerary.photo);
      // const itinerariyDay3 = res[0].itinerary
      //   .filter((itinerary) => itinerary.name === "Day 3")
      //   .map((itinerary) => itinerary.photo);
      // const itinerariyDay4 = res[0].itinerary
      //   .filter((itinerary) => itinerary.name === "Day 4")
      //   .map((itinerary) => itinerary.photo);

      // const itinerariesLocal = [];
      // if (itinerariyDay1 && itinerariyDay1.length > 0) {
      //   itinerariesLocal.push(itinerariyDay1);
      // }

      // if (itinerariyDay2 && itinerariyDay2.length > 0) {
      //   itinerariesLocal.push(itinerariyDay2);
      // }

      // if (itinerariyDay3 && itinerariyDay3.length > 0) {
      //   itinerariesLocal.push(itinerariyDay3);
      // }

      // if (itinerariyDay4 && itinerariyDay4.length > 0) {
      //   itinerariesLocal.push(itinerariyDay4);
      // }

      // setItineraries(itinerariesLocal);

      const index = res[1].availableDates.findIndex((date) => {
        // check only the date part
        return (
          dayjs(date).format("YYYY-MM-DD") ===
          dayjs(selectedDate).format("YYYY-MM-DD")
        );
      });

      if (index === -1) {
        setPackages(res[0]?.packages);
        setSelectedDate(null);
      } else {
        setPackages(
          res[0]?.packages.filter(
            (p) => p.vehicles <= res[1].seatsAvailable[index]
          ) || []
        );
      }
    }

    asyncCall();
  }, []);

  useEffect(() => {
    // set whats included from req[0].whatsIncluded  as array of array of string of length 4
    const whatsIncludedLocal =
      localStorage.getItem("i18nextLng") === "ro"
        ? tour?.whatsIncludedRo || []
        : tour?.whatsIncluded || [];
    const whatsIncludedArray: string[][] = [];
    for (let i = 0; i < whatsIncludedLocal.length; i += 4) {
      whatsIncludedArray.push(whatsIncludedLocal.slice(i, i + 4));
    }
    setWhatsIncluded(whatsIncludedArray);
  }, [tour, localStorage.getItem("i18nextLng")]);

  useEffect(() => {
    if (selectedDate) {
      const index = availableDates.findIndex((date) => {
        // check only the date part
        return (
          dayjs(date).format("YYYY-MM-DD") ===
          dayjs(selectedDate).format("YYYY-MM-DD")
        );
      });

      if (tour?.startLocation !== "Training") {
        setSelectedPackage("");
      }

      setPackages(
        tour?.packages.filter((p) => p.vehicles <= seatsAvailable[index]) || []
      );
    }
  }, [selectedDate]);

  useEffect(() => {
    if (bigImgSliderRef.current && thumbnailSliderRef.current) {
      bigImgSliderRef.current.slickGoTo(0);
      thumbnailSliderRef.current.slickGoTo(0);
    }
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  const settings1 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      {window.location.href.includes("training") ? (
        <>
          {localStorage.getItem("i18nextLng") === "ro" ? (
            <Helmet>
              <link
                rel="canonical"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/`}
              />

              <link
                rel="alternate"
                hrefLang="ro"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/`}
              />
              <link
                rel="alternate"
                hrefLang="en"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/?lang=en`}
              />
              <title>
                Training 4x4 Profesional | Cursuri Off-Road - First Gear Club
              </title>
              <meta
                name="description"
                content="Invata tehnici de condus off-road cu trainingul 4x4. Sub indrumarea expertului nostru, dobandeste abilitati esentiale pentru a stapani cele mai dificile terenuri din Romania."
              />
            </Helmet>
          ) : (
            <Helmet>
              <link
                rel="canonical"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/`}
              />

              <link
                rel="alternate"
                hrefLang="ro"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/`}
              />
              <link
                rel="alternate"
                hrefLang="en"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/?lang=en`}
              />
              <title>
                Professional 4x4 Training | Off-Road Courses - First Gear Club
              </title>
              <meta
                name="description"
                content="Learn off-road driving techniques with the 4x4 training. Under the guidance of our expert, gain essential skills to master the most challenging terrains in Romania."
              />
            </Helmet>
          )}
        </>
      ) : (
        <>
          {localStorage.getItem("i18nextLng") === "ro" ? (
            <Helmet>
              <link
                rel="canonical"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/`}
              />

              <link
                rel="alternate"
                hrefLang="ro"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/`}
              />
              <link
                rel="alternate"
                hrefLang="en"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/?lang=en`}
              />
              <title>
                {`Traseu off-road ${tour?.name} - Tur de ${tour?.numberOfDays === 1 ? "o zi" : `${tour?.numberOfDays.toString()} zile`} - First Gear Club`}
              </title>
              <meta
                name="description"
                content={`Vezi datele disponibile si experimenteaza traseul off-road ${tour?.name} de ${tour?.numberOfDays.toString()} zile ⭐ Rezerva direct pe site simplu si rapid ⭐ Ghid experimentat`}
              />
            </Helmet>
          ) : (
            <Helmet>
              <link
                rel="canonical"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/`}
              />

              <link
                rel="alternate"
                hrefLang="ro"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/`}
              />
              <link
                rel="alternate"
                hrefLang="en"
                href={`https://firstgearclub.com/tur/${tour?.name.replaceAll(" ", "-")}-${tourId}/?lang=en`}
              />
              <title>
                {`Off-Road tour in ${tour?.name} - ${tour?.numberOfDays === 1 ? "one day" : `${tour?.numberOfDays.toString()} days`} Tour - First Gear Club`}
              </title>
              <meta
                name="description"
                content={`See available dates and experience the ${tour?.name} off-road tour for ${tour?.numberOfDays.toString()} ⭐ Book directly on the website quickly and easily ⭐ Experienced guide.`}
              />
            </Helmet>
          )}
        </>
      )}

      <Breadcrumbs
        items={[
          {
            link: "",
            name: i18n.language === "ro" ? tour?.nameRo || "" : tour?.name || ""
          }
        ]}
        title={`${i18n.language === "ro" ? tour?.nameRo || "" : tour?.name || ""}`}
      />
      <div className="main-wrapper">
        {/* Detail Page Head*/}
        <section className="product-detail-head">
          <div className="container">
            <div className="detail-page-head">
              <div className="detail-headings">
                <div className="star-rated">
                  <ul className="list-rating">
                    <li className="ratings">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <span className="d-inline-block average-list-rating">
                        (5.0)
                      </span>
                    </li>
                  </ul>
                  <div className="camaro-info">
                    <h1>
                      {i18n.language === "ro" ? tour?.nameRo : tour?.name}
                    </h1>
                    <div className="camaro-location">
                      <div className="camaro-location-inner">
                        <i className="bx bx-map" />
                        {tour?.startLocation !== "Training" ? (
                          <>
                            <span>
                              <b>{t("tourPage.startLocationLabel")}</b>{" "}
                              {tour?.startLocation === "Bucharest"
                                ? "Bucharest, Romania"
                                : "Brasov, Romania"}
                            </span>
                          </>
                        ) : (
                          <>
                            <span>
                              {t("home.tourSection.trainingLocation")}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="camaro-location-inner">
                        <i className="feather icon-clock me-2" />
                        {t("tourPage.durationLabel")}{" "}
                        <b className="ms-1">
                          {" "}
                          {tour?.numberOfDays}{" "}
                          {(tour?.numberOfDays || 1) === 1
                            ? t("home.tourSection.numberOfDaysLabel_one")
                            : t("home.tourSection.numberOfDaysLabel_other")}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Detail Page Head*/}

        <section className="section product-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="detail-product">
                  <div className="slider detail-bigimg">
                    <Slider {...settings1}>
                      {tour?.photos.map((img) => (
                        <div className="product-img" key={img}>
                          <img
                            src={`${process.env.REACT_APP_STORAGE_BASE_URL}/tours/${tour?.id}/${img}`}
                            alt="Slider"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <>
                  {/* {tour?.startLocation !== "Training" && (
                    <div className="review-sec mb-0">
                      <div className="review-header">
                        <h4>{t("tourPage.itineraryLabel")}</h4>
                      </div>
                      <div className="description-list">
                        <div className="container">
                          <div className="row">
                            {itineraries.map((itinerary, index) => (
                              <>
                                <Col
                                  md={6}
                                  className="col-12 text-center"
                                  key={index}
                                >
                                  <h3>
                                    {t("tourPage.dayLabel")} {index + 1}
                                  </h3>
                                  <div className="detail-product">
                                    <div className="slider detail-bigimg">
                                      <Slider {...settings1}>
                                        {itinerary.map((img) => (
                                          <div
                                            className="product-img"
                                            key={img}
                                          >
                                            <img
                                              src={`${process.env.REACT_APP_STORAGE_BASE_URL}/tours/${tour?.id}/itinerary/${img}`}
                                              alt="Slider"
                                            />
                                          </div>
                                        ))}
                                      </Slider>
                                    </div>
                                  </div>
                                </Col>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                  {/* Listing Section */}
                  <div className="review-sec mb-0">
                    <div className="review-header">
                      <h4>{t("tourPage.descriptionLabel")}</h4>
                    </div>
                    <div className="description-list">
                      {tour?.startLocation !== "Training" &&
                        localStorage.getItem("i18nextLng") !== "en" && (
                          <h2>
                            Traseu off-road 4x4 de{" "}
                            {tour?.numberOfDays === 1
                              ? "o zi"
                              : `${tour?.numberOfDays.toString()} zile`}{" "}
                            in {tour?.nameRo.split("Traseu Off-Road").pop()}
                          </h2>
                        )}
                      <p>
                        <b>{t("tourPage.startAtLabel")}</b>
                      </p>
                      <p style={{ whiteSpace: "pre-line" }}>
                        {i18n.language === "ro"
                          ? tour?.descriptionRo
                          : tour?.description}
                      </p>
                    </div>
                  </div>
                  {/* /Listing Section */}
                  {/* Car Features */}
                  <div className="review-sec listing-feature">
                    <div className="review-header">
                      <h4>{t("tourPage.whatsIncludedLabel")} </h4>
                    </div>
                    <div className="listing-description">
                      <div className="row">
                        {whatsIncluded.map((item, index) => (
                          <div className="col-md-4" key={index}>
                            <ul>
                              {item.map((i) => (
                                <li key={i}>
                                  <span>
                                    <i className="bx bx-check-double" />
                                  </span>
                                  {i}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* /Car Features */}

                  {/* Video */}
                  <div className="review-sec mb-0">
                    <div className="review-header">
                      <h4>{t("tourPage.videoLabel")}</h4>
                    </div>
                    <div className="short-video">
                      <iframe
                        src="https://www.youtube.com/embed/NT2FdWFRJto"
                        width={100}
                        height={350}
                      />
                    </div>
                  </div>
                  {/* /Video */}
                </>
              </div>
              <div className="col-lg-4 theiaStickySidebar">
                <div className="stickybar">
                  <div className="review-sec mt-0">
                    <div className="review-header">
                      <h4>{t("tourPage.bookTourLabel")} </h4>
                    </div>
                    <div className="location-content">
                      <div className="" id="">
                        <ul>
                          {tour?.tourDates && tour?.tourDates.length > 0 ? (
                            <>
                              <li className="column-group-main">
                                <div className="input-block m-0">
                                  {tour?.startLocation !== "Training" ? (
                                    <label>
                                      {t("tourPage.tourStartDateLabel")}
                                    </label>
                                  ) : (
                                    <label>
                                      {t("tourPage.trainingDateLabel")}
                                    </label>
                                  )}
                                </div>
                                <div className="input-block-wrapp sidebar-form">
                                  <div className="input-block me-lg-2 w-100">
                                    <div className="group-img">
                                      <div className="form-wrap">
                                        <Calendar
                                          value={selectedDate}
                                          defaultActiveStartDate={
                                            availableDates &&
                                            availableDates.length > 0
                                              ? // sort available dates by newest first
                                                availableDates.sort(
                                                  (a, b) =>
                                                    dayjs(a).valueOf() -
                                                    dayjs(b).valueOf()
                                                )[0]
                                              : new Date()
                                          }
                                          onChange={(e) => {
                                            const localDate = e as Date;
                                            if (!localDate) {
                                              return;
                                            }

                                            const finishDates =
                                              availableDates.map((d) =>
                                                dayjs(d).add(
                                                  (tour?.numberOfDays || 1) - 1,
                                                  "day"
                                                )
                                              );

                                            if (
                                              !availableDates.find(
                                                (d) =>
                                                  // check yyyy-mm-dd
                                                  dayjs(d).format(
                                                    "YYYY-MM-DD"
                                                  ) ===
                                                  dayjs(localDate).format(
                                                    "YYYY-MM-DD"
                                                  )
                                              ) &&
                                              !availableDates.find(
                                                (d, index) => {
                                                  const finishDate =
                                                    finishDates[index];
                                                  return (
                                                    dayjs(localDate).format(
                                                      "YYYY-MM-DD"
                                                    ) >
                                                      dayjs(d).format(
                                                        "YYYY-MM-DD"
                                                      ) &&
                                                    dayjs(localDate).format(
                                                      "YYYY-MM-DD"
                                                    ) <=
                                                      dayjs(finishDate).format(
                                                        "YYYY-MM-DD"
                                                      )
                                                  );
                                                }
                                              )
                                            ) {
                                              return;
                                            }

                                            const foundDate =
                                              availableDates.find(
                                                (d, index) => {
                                                  const finishDate =
                                                    finishDates[index];
                                                  return (
                                                    dayjs(localDate).format(
                                                      "YYYY-MM-DD"
                                                    ) >
                                                      dayjs(d).format(
                                                        "YYYY-MM-DD"
                                                      ) &&
                                                    dayjs(localDate).format(
                                                      "YYYY-MM-DD"
                                                    ) <=
                                                      dayjs(finishDate).format(
                                                        "YYYY-MM-DD"
                                                      )
                                                  );
                                                }
                                              );

                                            if (foundDate) {
                                              setSelectedDate(
                                                new Date(
                                                  Date.UTC(
                                                    foundDate.getFullYear(),
                                                    foundDate.getMonth(),
                                                    foundDate.getDate()
                                                  )
                                                )
                                              );
                                              return;
                                            }

                                            setSelectedDate(
                                              new Date(
                                                Date.UTC(
                                                  localDate.getFullYear(),
                                                  localDate.getMonth(),
                                                  localDate.getDate()
                                                )
                                              )
                                            );
                                          }}
                                          className="w-100"
                                          tileClassName={(elems) => {
                                            const localDate =
                                              elems.date as Date;
                                            const view = elems.view;
                                            if (view !== "month") {
                                              return "";
                                            }

                                            const indexOfSelected =
                                              availableDates.findIndex(
                                                (d) =>
                                                  dayjs(d).format(
                                                    "YYYY-MM-DD"
                                                  ) ===
                                                  dayjs(selectedDate).format(
                                                    "YYYY-MM-DD"
                                                  )
                                              );
                                            if (
                                              indexOfSelected >= 0 &&
                                              dayjs(
                                                availableDates[indexOfSelected]
                                              ).format("YYYY-MM-DD") ===
                                                dayjs(localDate).format(
                                                  "YYYY-MM-DD"
                                                ) &&
                                              (tour?.numberOfDays || 1) === 1
                                            ) {
                                              return "active-date";
                                            }

                                            if (
                                              indexOfSelected >= 0 &&
                                              dayjs(
                                                availableDates[indexOfSelected]
                                              ).format("YYYY-MM-DD") ===
                                                dayjs(localDate).format(
                                                  "YYYY-MM-DD"
                                                ) &&
                                              (tour?.numberOfDays || 1) > 1
                                            ) {
                                              return "active-date-start";
                                            }

                                            if (indexOfSelected >= 0) {
                                              const finishDate = dayjs(
                                                availableDates[indexOfSelected]
                                              ).add(
                                                (tour?.numberOfDays || 1) - 1,
                                                "day"
                                              );
                                              if (
                                                dayjs(localDate).format(
                                                  "YYYY-MM-DD"
                                                ) ===
                                                finishDate.format("YYYY-MM-DD")
                                              ) {
                                                return "active-date-end";
                                              }

                                              if (
                                                dayjs(localDate).format(
                                                  "YYYY-MM-DD"
                                                ) >
                                                  dayjs(
                                                    availableDates[
                                                      indexOfSelected
                                                    ]
                                                  ).format("YYYY-MM-DD") &&
                                                dayjs(localDate).format(
                                                  "YYYY-MM-DD"
                                                ) <
                                                  finishDate.format(
                                                    "YYYY-MM-DD"
                                                  )
                                              ) {
                                                return "active-date-middle";
                                              }
                                            }

                                            const indexAvailable =
                                              availableDates
                                                .map((d) =>
                                                  dayjs(d).format("YYYY-MM-DD")
                                                )
                                                .indexOf(
                                                  dayjs(localDate).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                );

                                            let color = tour?.color || "2e3840";
                                            if (indexAvailable >= 0) {
                                              color = tour?.color || "2e3840";
                                              // create a class called highlight-{color} and add it to css
                                              // to style the dates with the color
                                              const style =
                                                document.createElement("style");
                                              style.type = "text/css";
                                              style.innerHTML = `.highlight-${color} { background-color: #${color}; }`;
                                              document
                                                .getElementsByTagName("head")[0]
                                                .appendChild(style);
                                            }

                                            if (
                                              indexAvailable >= 0 &&
                                              (tour?.numberOfDays || 1) === 1
                                            ) {
                                              return `highlight-date highlight-${color}`;
                                            }

                                            if (
                                              indexAvailable >= 0 &&
                                              (tour?.numberOfDays || 1) > 1
                                            ) {
                                              return `highlight-date-start highlight-${color}`;
                                            }

                                            const finishDates =
                                              availableDates.map((d) =>
                                                dayjs(d).add(
                                                  (tour?.numberOfDays || 1) - 1,
                                                  "day"
                                                )
                                              );

                                            const indexFinish = finishDates
                                              .map((d) =>
                                                dayjs(d).format("YYYY-MM-DD")
                                              )
                                              .indexOf(
                                                dayjs(localDate).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );

                                            // create a class called highlight-{color} and add it to css
                                            // to style the dates with the color
                                            const style =
                                              document.createElement("style");
                                            style.type = "text/css";
                                            style.innerHTML = `.highlight-${color} { background-color: #${color}; }`;
                                            document
                                              .getElementsByTagName("head")[0]
                                              .appendChild(style);

                                            if (indexFinish >= 0) {
                                              return `highlight-date-end highlight-${color} `;
                                            }

                                            const filterDate =
                                              availableDates.filter(
                                                (d, index) =>
                                                  dayjs(d).format(
                                                    "YYYY-MM-DD"
                                                  ) <
                                                    dayjs(localDate).format(
                                                      "YYYY-MM-DD"
                                                    ) &&
                                                  dayjs(localDate).format(
                                                    "YYYY-MM-DD"
                                                  ) <
                                                    dayjs(
                                                      finishDates[index]
                                                    ).format("YYYY-MM-DD")
                                              );

                                            if (filterDate.length > 0) {
                                              // create a class called highlight-{color} and add it to css
                                              // to style the dates with the color
                                              const style =
                                                document.createElement("style");
                                              style.type = "text/css";
                                              style.innerHTML = `.highlight-${color} { background-color: #${color}; }`;
                                              document
                                                .getElementsByTagName("head")[0]
                                                .appendChild(style);
                                              return `highlight-date-middle highlight-${color}`;
                                            }

                                            if (
                                              soldOutDates.find(
                                                (d) =>
                                                  dayjs(d).format(
                                                    "YYYY-MM-DD"
                                                  ) ===
                                                  dayjs(localDate).format(
                                                    "YYYY-MM-DD"
                                                  )
                                              )
                                            ) {
                                              return "calendar-element-sold";
                                            }

                                            return "disabled-date";
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              {selectedDate && (
                                <li className="column-group-main">
                                  {tour?.startLocation !== "Training" && (
                                    <>
                                      <h6>
                                        {t("tourPage.availableRidesLabel")}{" "}
                                        {
                                          seatsAvailable[
                                            availableDates.findIndex(
                                              (date) =>
                                                dayjs(date).format(
                                                  "YYYY-MM-DD"
                                                ) ===
                                                dayjs(selectedDate).format(
                                                  "YYYY-MM-DD"
                                                )
                                            )
                                          ]
                                        }{" "}
                                        / 8{/* TODO: change this later on */}
                                      </h6>
                                      <div className="input-block m-0">
                                        {" "}
                                        <label>
                                          {t("tourPage.selectPackageLabel")}
                                        </label>
                                      </div>
                                    </>
                                  )}

                                  <div className="delivery-tab">
                                    <ul className="nav d-flex flex-column">
                                      <li>
                                        {packages.map((p) => (
                                          <label
                                            className="booking_custom_check mb-2"
                                            key={p.name}
                                          >
                                            <input
                                              type="radio"
                                              name="rent_type"
                                              style={{ display: "none" }}
                                              onChange={() =>
                                                setSelectedPackage(p.name)
                                              }
                                              checked={
                                                selectedPackage === p.name
                                              }
                                            />
                                            <div className="booking_checkmark d-flex flex-column align-items-start">
                                              <div className="d-flex flex-row justify-content-between w-100">
                                                <span className="checked-title">
                                                  {p.name}
                                                </span>
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "1.2rem"
                                                  }}
                                                >
                                                  € {p.price}
                                                </div>
                                              </div>
                                              <div className="d-flex flex-row justify-content-between w-100">
                                                <div className="d-flex align-items-center">
                                                  <ImageWithBasePath
                                                    src="assets/img/specification/specification-icon-6.svg"
                                                    alt="Icon"
                                                    height={15}
                                                    className="me-1"
                                                  />
                                                  {tour?.startLocation !==
                                                  "Training" ? (
                                                    <>
                                                      {p.rides == 1 &&
                                                      p.vehicles == 1 ? (
                                                        <span>
                                                          {t(
                                                            "tourPage.driver1nondriverLabel"
                                                          )}
                                                        </span>
                                                      ) : p.rides == 2 &&
                                                        p.vehicles == 2 ? (
                                                        <span>
                                                          {t(
                                                            "tourPage.driver2nondriverLabel"
                                                          )}
                                                        </span>
                                                      ) : p.rides == 4 &&
                                                        p.vehicles == 2 ? (
                                                        <span>
                                                          4{" "}
                                                          {t(
                                                            "tourPage.driverOtherLabel"
                                                          )}
                                                        </span>
                                                      ) : p.rides == 1 &&
                                                        p.vehicles == 2.5 ? (
                                                        <span>
                                                          1{" "}
                                                          {t(
                                                            "tourPage.driverOneLabel"
                                                          )}
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          {p.rides}{" "}
                                                          {t(
                                                            "tourPage.driverOneLabel"
                                                          )}
                                                        </span>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <span>
                                                      {" "}
                                                      {t(
                                                        "tourPage.driver1Label"
                                                      )}
                                                    </span>
                                                  )}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                  <ImageWithBasePath
                                                    src="assets/img/specification/specification-icon-1.svg"
                                                    alt="Icon"
                                                    height={15}
                                                    className="me-1"
                                                  />
                                                  {p.vehicles === 0.5
                                                    ? "1/2"
                                                    : p.vehicles}{" "}
                                                  {p.vehicles > 1
                                                    ? `${t("tourPage.vehicleLabel_other")}`
                                                    : `${t("tourPage.vehicleLabel_one")}`}
                                                </div>
                                              </div>
                                            </div>
                                          </label>
                                        ))}
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              )}
                              <li className="column-group-last">
                                <div className="input-block mb-0">
                                  <div className="search-btn">
                                    <a
                                      href={`/checkout/${tourId}/${selectedDate?.getTime()}/${selectedPackage}`}
                                      className="btn btn-primary check-available w-100"
                                      id="book-now-btn"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (!selectedDate) {
                                          alert(
                                            t(
                                              "tourPage.alerts.selectADateAlert"
                                            )
                                          );
                                          return;
                                        }
                                        if (!selectedPackage) {
                                          alert(
                                            t(
                                              "tourPage.alerts.selectAPackageAlert"
                                            )
                                          );
                                          return;
                                        }

                                        // @ts-ignore
                                        window.dataLayer =
                                          // @ts-ignore
                                          window.dataLayer || [];
                                        // @ts-ignore
                                        window.dataLayer.push({ items: null });
                                        // @ts-ignore
                                        window.dataLayer.push({
                                          event: "add_to_cart",
                                          currency: "EUR",
                                          value: 500,
                                          items: [
                                            {
                                              item_id: tourId,
                                              item_name: tour?.name,
                                              item_category:
                                                tour?.startLocation,
                                              item_variant: selectedPackage,
                                              price: packages.find(
                                                (p) =>
                                                  p.name === selectedPackage
                                              )?.price,
                                              quantity: 1,
                                              index: 0
                                            }
                                          ]
                                        });

                                        window.location.href = `/checkout/${tourId}/${selectedDate?.getTime()}/${selectedPackage}`;
                                      }}
                                    >
                                      {t("tourPage.bookLabel")}
                                    </a>
                                  </div>
                                  <div
                                    className="w-100 d-flex justify-content-center align-items-center mt-3"
                                    style={{
                                      color: "#28a745",
                                      border: "none",
                                      fontWeight: "500"
                                    }}
                                  >
                                    <svg
                                      className=""
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      height={12}
                                    >
                                      <path
                                        d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                    {t("tourPage.bestPriceLabel")}
                                    <span className=""></span>
                                  </div>
                                </div>
                              </li>
                            </>
                          ) : (
                            <ContactForm />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-5">
            <div className="row mt-5">
              <div className="popular-slider-group mt-4">
                <div className=" rental-deal-slider ">
                  <Slider {...rentalslideroption} className="rental-slider">
                    {/* owl carousel item */}
                    {tours.map((tour, index) => (
                      <div className="rental-car-item" key={tour.id}>
                        <div className="listing-item mb-0">
                          <div className="listing-img">
                            <a
                              href={`/tur/${tour?.name.replaceAll(" ", "-")}-${tour.id}/`}
                              onClick={() => {
                                // @ts-ignore
                                window.dataLayer = window.dataLayer || [];
                                // @ts-ignore
                                // @ts-ignore
                                window.dataLayer.push({ items: null });
                                // @ts-ignore
                                window.dataLayer.push({
                                  event: "select_item",
                                  currency: "EUR",
                                  item_list_name: tour.name,
                                  items: [
                                    {
                                      item_id: tour.id,
                                      item_name: tour.name,
                                      item_category: tour.startLocation,
                                      item_variant: "",
                                      price: tour.packages[0].price,
                                      quantity: 1,
                                      index: index + 1
                                    }
                                  ]
                                });
                              }}
                            >
                              <img
                                src={`${process.env.REACT_APP_STORAGE_BASE_URL}/tours/${tour?.id}/${tour?.photos[0]}`}
                                className="img-fluid"
                                alt={tour.name}
                                loading="lazy"
                              />
                            </a>
                          </div>
                          <div className="listing-content">
                            <div className="listing-features">
                              <div className="fav-item-rental">
                                <div
                                  className="featured-text"
                                  style={{ background: `#${tour.color}` }}
                                >
                                  {t("home.tourSection.featureText", {
                                    price: tour.packages[0].price * 0.95
                                  })}
                                </div>
                              </div>
                              <div className="list-rating">
                                <i className="fas fa-star filled" />
                                <i className="fas fa-star filled" />
                                <i className="fas fa-star filled" />
                                <i className="fas fa-star filled" />
                                <i className="fas fa-star filled" />
                                <span>(5.0)</span>
                              </div>
                              <h2 className="listing-title">
                                <a
                                  href={`/tur/${tour?.name.replaceAll(" ", "-")}-${tour.id}/`}
                                >
                                  {i18n.language === "ro"
                                    ? tour.nameRo
                                    : tour.name}
                                </a>
                              </h2>
                              <p>
                                <i className="feather icon-map-pin me-2" />
                                {t("home.tourSection.startLocationLabel")}:{" "}
                                <b>{tour.startLocation}</b>
                              </p>
                              <p>
                                <i className="feather icon-clock me-2" />
                                {t("home.tourSection.durationLabel")}:{""}
                                <b>
                                  {" "}
                                  {tour.numberOfDays}{" "}
                                  {tour.numberOfDays === 1
                                    ? t(
                                        "home.tourSection.numberOfDaysLabel_one"
                                      )
                                    : t(
                                        "home.tourSection.numberOfDaysLabel_other"
                                      )}
                                </b>
                                {date && tour.numberOfDays > 1 ? (
                                  <span className="text-danger">
                                    {" "}
                                    {t("home.tourSection.warningDifferentDate")}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            <div className="listing-button">
                              <a
                                href={`/tur/${tour?.name.replaceAll(" ", "-")}-${tour.id}/`}
                                className="btn btn-order"
                                onClick={() => {
                                  // @ts-ignore
                                  window.dataLayer = window.dataLayer || [];
                                  // @ts-ignore
                                  // @ts-ignore
                                  window.dataLayer.push({ items: null });
                                  // @ts-ignore
                                  window.dataLayer.push({
                                    event: "select_item",
                                    currency: "EUR",
                                    item_list_name: tour.name,
                                    items: [
                                      {
                                        item_id: tour.id,
                                        item_name: tour.name,
                                        item_category: tour.startLocation,
                                        item_variant: "",
                                        price: tour.packages[0].price,
                                        quantity: 1,
                                        index: index + 1
                                      }
                                    ]
                                  });
                                }}
                              >
                                <span>
                                  <i className="feather icon-calendar me-2" />
                                </span>
                                {t("home.tourSection.buttonLabel")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TourPage;
