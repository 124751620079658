import React, { useEffect, useState } from "react";
import { AdminService, Article } from "@genezio-sdk/westtigermotors";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "react-bootstrap";

const Blog = () => {
  const [articles, setArticles] = useState<Article[]>([]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AdminService.getArticles().then((res) => {
      setArticles(res);
    });
  }, []);

  return (
    <>
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: `${t("blog.title")}`
          }
        ]}
        title={`${t("blog.title")}`}
      />
      <Container>
        <Row className="mt-3">
          <Col xs={12} md={12}>
            <h1
              style={{
                fontSize: "2.5rem"
              }}
            >
              {t("blog.title")}
            </h1>
            <p className="mt-2">{t("blog.description")}</p>
          </Col>
        </Row>
        <div className="mt-2">
          {articles.map((article, index) => (
            <a href={`/blog/${article.id}-${article.titleRo}`} key={index}>
              <Row className="my-3">
                <Col xs={12} md={6} className="my-2">
                  <img
                    src={`${process.env.REACT_APP_STORAGE_BASE_URL}/articles/${article.id}/${article.sections[0].graphicUrl}`}
                    alt={article.titleRo}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "10px"
                    }}
                  />
                </Col>
                <Col xs={12} md={6} className="my-2">
                  <h4
                    style={{
                      color: "gray"
                    }}
                  >
                    {i18n.language === "ro" ? article.dateRo : article.dateEn}
                  </h4>
                  <h3 className="mt-3">
                    {i18n.language === "ro" ? article.titleRo : article.titleEn}
                  </h3>
                </Col>
              </Row>
            </a>
          ))}
        </div>
      </Container>
    </>
  );
};

export default Blog;
